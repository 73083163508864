// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//stage
export const environment = {
  //  apiUrl: 'https://koxev34z1k.execute-api.ap-south-1.amazonaws.com/test/',
	apiUrl: 'https://0v4dy6r9r4.execute-api.ap-south-1.amazonaws.com/dev/',
  // apiUrl: "https://pokqpc6hj6.execute-api.ap-south-1.amazonaws.com/stage/",

    BaseUrl: 'https://d2omsovar30p67.cloudfront.net/',

  production: false,
  firebase: {
    apiKey: "AIzaSyCyttSvlXpJWPsGLBgfxsnKKK95os2-HfQ",
    authDomain: "buki-8addc.firebaseapp.com",
    projectId: "buki-8addc",
    storageBucket: "buki-8addc.appspot.com",
    messagingSenderId: "705846407545",
    appId: "1:705846407545:web:e249fad1c176f0b6343fa8",
    measurementId: "G-6NNWSP95DE"
  },
  appleClientId:'com.example.bukiUserSalonDevService'
};


/*6.0",
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
